<template>
    <textarea ref="input" :disabled="props.noEdit" v-model="value"
    :class="{'o365-textarea-noresize': noResize}" 
    v-bind="$attrs" :style="{ 'height': textareaHeight}"
    @keydown="onKeyDown"
    @input="onInput" :rows="rows"></textarea>
</template>

<script setup lang="ts">
// DO NOT ADD COMMENTS OR OTHER NODES ON TEMPALTE ROOT
// This is an input editor therefore it must have a single root noode
// v-if-else chains are ok as long as only one node is rendered

import { InjectionKeys } from 'o365-utils';
import { ref, computed, onMounted, onUpdated, inject, onActivated, watch, nextTick } from 'vue';

const props = defineProps({
    autoGrow: Boolean,
    noResize: Boolean,
    noEdit: Boolean,
    rowHeight: {
        type: [Number, String],
        default: 28
    },
    rows: {
        type: [Number, String],
        default: 2
    },
    maxRows: {
        type: [Number, String],
    },
    modelValue: null,
    minRowHeightBuffer: {
        type: Number,
        default: 6
    }
});

const emit = defineEmits(['update:modelValue', 'heightUpdated']);

const value = computed({
    get() { return props.modelValue; },
    set(newValue) { emit('update:modelValue', newValue); }
});

const editorApi = inject<any>(InjectionKeys.dataGridEditorCellControlKey, null);
if (editorApi) {
    editorApi.activatePopupMode();
}

const input = ref<HTMLTextAreaElement>();
const textareaHeight = ref<string>();
async function calculateInputHeight() {
    if (!input.value) { return; }

    textareaHeight.value = 'auto'
    await nextTick();
    
    const rows = parseInt(props.rows as string, 10);
    const rowHeight = parseFloat(props.rowHeight as string);

    const height = props.maxRows
        ? Math.min(input.value.scrollHeight, parseInt(props.maxRows as string)*rowHeight)
        : input.value.scrollHeight;
    const computedMinHeight = rows * rowHeight + props.minRowHeightBuffer;
    const newHeight = Math.max(computedMinHeight, height + 2);
    textareaHeight.value = `${newHeight}px`;
    await nextTick();
    emit('heightUpdated', newHeight)
}

watch(() => props.modelValue, () => {
    if (props.autoGrow) {
        calculateInputHeight();
    }
});


function onInput(e) {
    if (props.autoGrow) { calculateInputHeight(); }
}

function onKeyDown(e) {
    if (editorApi && e.key === 'Enter' && e.shiftKey) {
        e.stopPropagation();
    }
}

function activateEditor() {
    const el = input.value;
    if (el) { el.focus(); }
}

function getControl() {
    return input.value
}

onMounted(() => {
    if (props.autoGrow) { calculateInputHeight(); }
});

// onUpdated(() => {
    // if (props.autoGrow) { calculateInputHeight(); }
// });

onActivated(() => {
    if (props.autoGrow) {
        window.setTimeout(() => {
            calculateInputHeight();
        }, 50);
    }
});

defineExpose({ activateEditor, getControl, calculateInputHeight });
</script>

<style scoped>
    .o365-textarea-noresize {
        resize: none;
    }
</style>